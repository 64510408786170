'use client'
import * as React from 'react'
import { useState } from 'react'
import { faqItem, FaqProps } from './types'
import Button from '../Button'
import FaqPlusIcon from '../../icons/faqPlusIcon'
import FaqMinIcon from '../../icons/faqMinIcon'
import Image from 'next/image'
import Link from 'next/link'
import classNames from 'classnames'

const FaqItem = ({
    item,
    index,
    setOpen,
    open,
}: {
    item: faqItem
    index: number
    setOpen: (p: null | number) => void
    open: number | null
}) => {
    const isOpen = open === index
    return (
        <div className="border-t-[1px] lg:border-t-0 lg:border-b-[1px] border-black/20 lg:max-w-[665px]">
            <div
                className={`flex h-20 items-center justify-between lg:hover:cursor-pointer transition duration-75 ease-in ${
                    isOpen ? '' : ''
                }`}
                onClick={() => {
                    setOpen(isOpen ? null : index)
                }}
            >
                <Button
                    aria-label={'Open veelgestelde vragen item'}
                    className={`${
                        isOpen ? 'bg-black' : 'bg-white'
                    } shrink-0 transition-colors duration-200 btn-round relative mr-5 shadow border border-[1px] border-black/20 rounded-full w-10 h-10`}
                >
                    {isOpen && <FaqMinIcon stroke="#fff" />}
                    {!isOpen && <FaqPlusIcon stroke="#000" />}
                </Button>
                <p className="small-mobile:w-10/12 w-11/12 font-bold">{item.question}</p>
            </div>
            <div
                className={classNames(
                    `transition-all duration-200`,
                    { 'visible opacity-100': isOpen },
                    { 'hidden opacity-0': !isOpen }
                )}
            >
                <div className="max-w-screen-md pb-4">{item.answer}</div>
            </div>
        </div>
    )
}

const Faq = ({ faqList, content }: FaqProps) => {
    const [open, setOpen] = useState<number | null>(null)

    return (
        <div className="overflow-hidden">
            <div className="container flex flex-col lg:flex-row my-10 md:my-16 lg:my-32">
                <div className="w-full lg:w-6/12 relative after:lg:block after:absolute after:bg-[rgb(247,243,255)] after:w-[200vw] lg:after:left-[-120px]  after:left-[-50vw] lg:after:w-[165%] after:h-auto  after:rounded-[10px] after:inset-0">
                    <div className="lg:pr-0 relative lg:max-w-[665px] z-20 pt-20 pb-56 lg:pt-36 lg:pb-32">
                        <div className="lg:max-w-[620px] w-full lg:[&>h2]:leading-[46px] [&>p]:mt-3">{content}</div>
                        <div className="flex flex-col mt-9">
                            {faqList.length > 0 &&
                                faqList.map((item, index) => (
                                    <FaqItem key={index} item={item} index={index} open={open} setOpen={setOpen} />
                                ))}
                        </div>
                        <Link
                            aria-label={'Stuur een email'}
                            target="_blank"
                            href={'mailto:info@goodchef.nl'}
                            className="btn btn-primary mt-5 lg:mt-10"
                        >
                            Vragen? Stuur ons een mailtje.
                        </Link>
                    </div>
                </div>
                <div className="w-full lg:w-6/12 flex relative min-h-[400px] -mt-36 lg:mt-0">
                    <div className="-rotate-6 absolute lg:top-28 -right-8 lg:-right-96 md:[&>img]:w-[730px] [&>img]:w-[371px] [&>img]:h-[400px] lg:[&>img]:w-[761px] lg:[&>img]:h-[820px] rounded-[10px] overflow-hidden">
                        <Image
                            src="/images/dish-2.jpeg"
                            alt="dish"
                            width={761}
                            height={820}
                            sizes="(max-width: 768px) 100vw, 50vw"
                        />
                    </div>
                </div>
                {/*<div className="hidden lg:flex lg:w-6/12 relative">*/}
                {/*    <div className="hidden top-28 lg:block -rotate-6 absolute -right-96 z-10 [&>img]:w-[761px] [&>img]:h-[820px] rounded-[10px] overflow-hidden">*/}
                {/*        <Image src="/images/dish-2.jpeg" alt="dish" width={761} height={820} />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Faq
